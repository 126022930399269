import React from "react";
import axios from "axios";
import Property from "./Property";
import update from 'immutability-helper';
import Time from 'react-time-format'
import Utils from "./Utils";

import "./comment.css";

const POST_UPDATABLE_ELEMENT_TAGS = ['name', 'tags', 'description', 'content'];
const DEFAULT_STATE = "default_state";
const CACHED_STATE = "cached_state";
const POSTS_ROOT_URL = Property.BASE_API_URL + '/api/posts/';

// https://stackoverflow.com/a/61548724/3598880
function deepCompare(arg1, arg2) {
  if (Object.prototype.toString.call(arg1) === Object.prototype.toString.call(arg2)) {
    if (arg1 && (typeof arg1 === 'object' || Array.isArray(arg1))) {
      if (Object.keys(arg1).length !== Object.keys(arg2).length) {
        return false;
      }
      return (Object.keys(arg1).every(function (key) {
        return deepCompare(arg1[key], arg2[key]);
      }));
    }
    return (arg1 === arg2);
  }
  return false;
}

// https://stackoverflow.com/a/728694/3598880
// or use copy = Object.assign({}, objectToCopyFrom)
function clone(obj) {
  if (null == obj || "object" !== typeof obj) return obj;
  let copy = obj.constructor();
  for (let attr in obj) {
    if (obj.hasOwnProperty(attr)) copy[attr] = obj[attr];
  }
  return copy;
}

class Post extends React.Component {
  defaultState = {
    _id: '',
    name: '',
    tags: '', //actually tags variable is of array type
    content: '',
    description: '',
    comments: [],
    created: '',
    updated: '',
    message: '',
    isUpdateDisabled: Utils.getAccessToken() == null,
    comment: {
      post_id: '',
      email: '',
      password: '',
      content: ''
    }
  };

  constructor(props) {
    super(props);
    this.state = Object.assign({}, clone(this.defaultState));
    this.getPostId = this.getPostId.bind(this);
    this.fetchPostFromServer();
    this.handleChange = this.handleChange.bind(this);
    this.handleCommentChange = this.handleCommentChange.bind(this);
    this.clearPostData = this.clearPostData.bind(this);
    this.updateFieldsColor = this.updateFieldsColor.bind(this);
    this.setCachedState = this.setCachedState.bind(this);
    this.getUpdatedState = this.getUpdatedState.bind(this);
    this.setDefaultState = this.setDefaultState.bind(this);
    this.getDefaultState = this.getDefaultState.bind(this);

  }

  getPostId(id) {
    if (!id) {
      id = window.location.pathname.replace("/post", "").replace("/", "");
    }
    // if (!id) {
    //   id = this.props.match.params.id; // not works in react 6
    // }
    return id
  }

  getHeaders() {
    return {
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Methods': 'PUT, POST, DELETE',
        'Authorization': "JWT " + Utils.getAccessToken()
      }
    };
  }

  componentDidMount() {
    this.updateFieldsColor();
  }

  componentDidUpdate(nextProps, nextState, nextContext) {
    // const id = this.state._id
    // const idInUrl = this.props.match.params.id;
  }

  componentWillUnmount = () => {
    // TODO: runs the method only in case url is changed to another component.
    //  if navigate to new Post or exit page then check is not called
    let id = this.getPostId(this.state._id);
    this.clearFromStorageIfNotModified(id);
  }

  fetchPostFromServer() {
    let id = this.getPostId();

    if (!id) {
      this.setDefaultState(clone(this.defaultState));
      return;
    }
    axios.get(POSTS_ROOT_URL + id)
      .then((response) => {
        let cachedState = this.getUpdatedState(id);
        if (!response.data.updated) {
          response.data.updated = "";
        }
        if (!cachedState) {
          this.setCachedState(response.data);
        }
        this.setDefaultState(response.data);
        let cookiesPostState = this.getUpdatedState(id);
        if (cookiesPostState && response.data._id === cookiesPostState._id) {
          this.setState(cookiesPostState);
        } else {
          this.setState(
            response.data
          );
        }
        console.log("fetchedPost: ", this.state);
        // this.updateFieldsColor();
      })
      .catch((error) => {
        try {
          console.error(error);
        } catch (exception) {
          console.error("Unknown error")
        }
        /*
        todo show NotFound page without changing url address.
        this also can be done on Main.js during Switch
        */

        // return <Redirect to='/dashboard' />
        // return () => <NotFound/>;
        // this.props.history.push("/sdfsfsefsefs");
        // <Route component={NotFound} />;
      });
    if (this.state.comment.content == null) {
      this.setState({comment: {...this.state.comment, content: ''}});
    }
  }

  handleChange(event) {
    if (!Utils.isLogged()){
      return;
    }
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.id;

    let cachedState = this.getUpdatedState(this.state._id);
    // todo: assert cookiesState !== null;
    if (cachedState[name] !== value) {
      this.state[name] = value;
      // this.setState({[name]: value}); // skips the first update symbol, works after the second
    }
    this.setState({
      [name]: value,
      updated: new Date().toISOString().slice(0, -5)})
    this.setCachedState(this.state);
    this.updateFieldsColor();
  }

  handleCommentChange(event) {
    //todo when a comment is saved all the data from this.state goes to blank (or default)
    let keys = event.target.id.split(".");
    const updatedData = update(this.state, {[keys[0]]: {[keys[1]]: {$set: event.target.value}}});
    this.setState(updatedData);
  }

  clearPostData() { // fixme. if pressed once then cannot modify post, if twice then deletes everything in state
    const id = this.getPostId(this.state._id);
    let originalState = this.getDefaultState(id);
    if (JSON.stringify(this.state) === JSON.stringify(originalState)) {
      return;
    }
    this.setCachedState(originalState);
    this.state = originalState;
    this.setState(originalState, () =>
      console.log("State was cleared to default (saved) state: ", originalState));
    this.updateFieldsColor();
    localStorage.removeItem(id);
  }

  deletePost() {
    const id = this.getPostId();
    console.log("delete post triggered id=", id)
    if (!id) {
      return;
    }
    const url = POSTS_ROOT_URL + id;
    axios.delete(url, this.getHeaders())
      .then(res => {
        console.log(res)
        this.setState({message: "deleted"});
        window.location.replace("/post");
      }).catch((error, status, info) => {
      let message = error.message === 'Network Error'
        ? 'Network Error: No response from backend'
        : error.response.status + " : " + error.response.data.message;
      console.log(error);
      this.setState({message: message});
    });
  }

  /**
   * it is to separate original state value for each post, store post cookies separately
   * to have ability to handle several posts at one time
   */
  setCachedState(updatedState) {
    if (updatedState == null) {
      updatedState = clone(this.defaultState);
    }
    let id = updatedState._id;
    id = this.getPostId(id)
    let defaultState = this.getDefaultState(id);
    let json_str = JSON.stringify({DEFAULT_STATE: defaultState, CACHED_STATE: updatedState});
    localStorage.setItem(id, json_str);
    console.info("set cached state id=" + id + ", cached=" + JSON.stringify(updatedState));
    let cachedState = this.getUpdatedState(id);
    if (!deepCompare(cachedState, updatedState)) {
      console.error("cached state was set and must not be null");
    }
  }

  /**
   * gets updated state depending on post id
   * can be not equal to what it is in database
   */
  getUpdatedState(id) {
    id = this.getPostId(id)
    let ob = localStorage.getItem(id);
    let json_ob = JSON.parse(ob);
    if (id) {
      return json_ob ? json_ob.CACHED_STATE : null;
    } else {
      return json_ob ? json_ob.CACHED_STATE : clone(this.defaultState);
    }
  }

  /**
   * it is saved state, equal to what is in database
   * it is expected to be called only once while fetching data on page load and no more
   */
  setDefaultState(state) {
    const id = this.getPostId(state._id);
    const cachedState = this.getUpdatedState(id);
    if (!cachedState) {
      console.error("Try to set cached state as null");
    }
    let json_str = JSON.stringify({DEFAULT_STATE: state, CACHED_STATE: cachedState});
    localStorage.setItem(id, json_str);
    console.debug(DEFAULT_STATE + ": " + JSON.stringify(state));
    let gotDefaultState = this.getDefaultState(id)
    if (!gotDefaultState) {
      console.error("Default set was set and must not be null!");
    }
  }

  getDefaultState(id) {
    id = this.getPostId(id)
    let ob = localStorage.getItem(id);
    let json_ob = JSON.parse(ob);
    let defaultState = json_ob ? json_ob.DEFAULT_STATE : clone(this.defaultState);
    if (defaultState == null) { //todo set default state properly and then delete this 'if'
      defaultState = clone(this.defaultState);
    }
    return defaultState;
  }

  updateFieldsColor() {
    let id = this.getPostId();;
    const defaultState = this.getDefaultState(id);
    const currentState = this.state;
    for (const elementId of POST_UPDATABLE_ELEMENT_TAGS) {
      const domElement = document.getElementById(elementId);
      if (!domElement) {
        continue;
      }
      const isFieldUpdated = this.checkFieldUpdated(elementId, defaultState, currentState);
      if (isFieldUpdated) {
        domElement.style.backgroundColor = Property.COLOR_PINK;
      } else {
        domElement.style.backgroundColor = "";
      }
    }
  }

  checkFieldUpdated(elementId, defaultState, currentState) {
    let defaultElement;
    let currentElement;
    try {
      defaultElement = JSON.stringify(defaultState[elementId]);
      currentElement = JSON.stringify(currentState[elementId]);
    } catch (err) {
      console.error("defaultElement=" + defaultElement + ", currentElement=" + currentElement);
      return;
    }
    return !deepCompare(defaultElement, currentElement);
  }

  clearFromStorageIfNotModified(id) {
    console.assert(id)
    const defaultState = this.getDefaultState(id);
    const currentState = this.state;
    let isStateNotSaved = false;
    for (const elementId of POST_UPDATABLE_ELEMENT_TAGS) {
      const domElement = document.getElementById(elementId);
      if (!domElement) {
        continue;
      }
      if (this.checkFieldUpdated(elementId, defaultState, currentState)) {
        isStateNotSaved = true;
        console.log("state is updated");
        return;
      }
    }
    console.log("state is not update, will remove from localStorage");
    localStorage.removeItem(id);
  }

  saveBlogPost = event => {
    event.preventDefault();
    let data = Object.assign({}, this.state); // copy value from this.state
    delete data._id;
    delete data.created;
    delete data.updated;

    /*
    TODO: make refresh synchronous, because after first press 'save' it writes then token is expired
    and after user press save for second time it really updates/creates a post
    */
    Utils.refreshAccessTokenIfNeeded();

    let saveResult = (this.state._id)
      ? this.updatePost(data)
      : this.createNewPost(data);
    if (saveResult) {
      this.setDefaultState(this.state);
      this.setCachedState(this.state);
    }
    setTimeout(function () {
      this.setState({message: ""});
    }.bind(this), Property.MESSAGE_TIMEOUT_MILLISECONDS);
  };

  updatePost(data) {
    const savePostUrl = POSTS_ROOT_URL + this.state._id;
    return axios.put(savePostUrl,
      data, this.getHeaders())
      .then(res => {
        this.setState({
          message: "Updated",
          updated: res.data.updated
        });
        console.log(res);
        console.log(res.data);
        this.updateFieldsColor();
        return true;
      })
      .catch(error => {
        Utils.showMessage(this, error);
        setTimeout(function () {
          this.setState({message: ""});
        }.bind(this), Property.MESSAGE_TIMEOUT_MILLISECONDS);
        return false;
      });
  }

  createNewPost(data) {
    return axios.post(POSTS_ROOT_URL.slice(0, -1),
      data, this.getHeaders())
      .then(res => {
        let message = "Created, id=" + res.data._id;
        this.setState({
          message: message,
          _id: res.data._id,
          created: res.data.created
        });
        this.updateFieldsColor();
      })
      .catch(error => {
        Utils.showMessage(this, error);
      });
  }

  showCommentForm = () => {
    this.setState({commentButtonIsVisible: !this.state.commentButtonIsVisible});
  };

  setComment = () => {
    let data = Object.assign({}, this.state.comment);
    data.post_id = this.state._id;
    axios.post(POSTS_ROOT_URL + this.state._id,
      data, this.getHeaders())
      .then(res => {
        this.setState({message: "Your comment has been added to the post"});
        setTimeout(function () {
          this.setState({
            message: "",
            "comment": this.defaultState.comment
          });
        }.bind(this), Property.MESSAGE_TIMEOUT_MILLISECONDS);
      })
      .catch(error => {
        Utils.showMessage(this, error);
        console.log(error);
        setTimeout(function () {
          this.setState({message: ""});
          this.setState(clone(this.defaultState));
        }.bind(this), Property.MESSAGE_TIMEOUT_MILLISECONDS);
      });
  };

  render() {
    if (this.getPostId() === undefined) {
      // todo state must be cleared, here we define the case when existing Post was opened and then user goes to create a new Post
      if (!this.state._id && document.getElementById("id")) { //fixme find more correct solution
        document.getElementById("id").value = null;
      }
      this.updateFieldsColor();
    }

    return (
      <div className="content-holder">
        <span id="message" value={this.state.message} style={{
          // position: "fixed",
          height: "50px",
          top: 0,
          left: 0,
          width: "100%"
        }}>{this.state.message}</span>
        <input type="text" id="id" value={this.state._id} disabled="disabled"/>
        <input type="text" placeholder="name*" id="name" value={this.state.name} onChange={this.handleChange}/>
        <textarea placeholder="tags" id="tags" value={this.state.tags} onChange={this.handleChange}/>
        <textarea placeholder="description" id="description" value={this.state.description}
                  onChange={this.handleChange}/>
        <textarea placeholder="content*" id="content" value={this.state.content} onChange={this.handleChange}/>
        <input type="datetime-local" id="created" value={this.state.created} disabled="disabled"/>
        <input type="datetime-local" id="updated" value={this.state.updated} disabled="disabled"/>
        <button hidden={!Utils.isLogged()} onClick={this.saveBlogPost}>Save</button>
        <button hidden={!Utils.isLogged()} onClick={this.clearPostData}>Return to saved state</button>
        <button hidden={!Utils.isLogged()} onClick={() => {
          if (window.confirm("Delete post?")) {
            this.deletePost()
          }
        }}>Delete post
        </button>
        <hr/>

        {this.getDefaultState(this.getPostId()).comments
          .map(item => <CommentMsg key={item.username + item.time} comment={item}/>)
        }

        <button style={{display: !this.state.commentButtonIsVisible ? 'block' : 'none'}}
                onClick={this.showCommentForm}>Add comment
        </button>

        <div className="comment-form" style={{display: this.state.commentButtonIsVisible ? 'block' : 'none'}}>
          {/*<span>On the first time you comment you register yourself in the system, the other times - password guarantees that nobody will write on your behalf</span>*/}
          <input type="email" id="comment.email" value={this.state.comment.email} placeholder="email"
                 onChange={this.handleCommentChange}/>
          <input type="text" id="comment.username" value={this.state.comment.username} placeholder="username"
                 onChange={this.handleCommentChange}/>
          {/*<input type="text" id="comment.password" value={this.state.comment.password} placeholder="password" onChange={this.handleCommentChange}/>*/}
          <textarea value={this.state.comment.content} placeholder="put your comment here" id="comment.content"
                    onChange={this.handleCommentChange}/>
          <button onClick={this.setComment}>Save comment</button>
        </div>
      </div>
    );
  }
}

class CommentMsg extends React.Component {
  render() {
    return (
      <div className={"comment-block"}
           key={this.props.comment.email}>
        <p className={"comment-block-username"}>{this.props.comment.username}</p>
        <p className={"comment-block-content"}>{this.props.comment.content}</p>
        <Time className={"comment-block-time"}
              value={this.props.comment.time}
              format="YYYY-MM-DD HH:MM"/>
      </div>
    )
  }
}

export default Post
