import React from 'react';

function getBaseApiUrl() {
  // locally the variable is set in .env file and in Cloud - with buildspec
  // using .env file: https://medium.com/how-to-react/using-env-file-in-react-js-b2714235e77e
  const REACT_APP_BASE_API_URL = process.env.REACT_APP_BASE_API_URL;
  return REACT_APP_BASE_API_URL ? REACT_APP_BASE_API_URL : "";
}

class Property extends React.Component {
  static BASE_API_URL = getBaseApiUrl();
  // static BASE_API_URL = "";
  static COLOR_GREEN = "#6f9";
  static COLOR_PINK = "#f47da070";

  static MESSAGE_TIMEOUT_MILLISECONDS = 7000;
}

export default Property