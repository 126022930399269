import React from 'react';
import axios from "axios";
import Cookies from "universal-cookie";
import {jwtDecode} from 'jwt-decode';

import Property from "./Property";

class Utils extends React.Component {
  static showMessage(componentObject, error) {
    let message = error.message;
    if (typeof error.response !== 'undefined' && typeof error.response.data !== 'undefined') {
      let msg = error.response.data.message ? error.response.data.message : error.response.data.msg;
      message = error.response.status + ":" + msg;
    }
    if (componentObject) {
      componentObject.setState({message: message});
      componentObject.state['message'] = message;
    }
  }

  static refreshAccessTokenIfNeeded(componentObject) {
    let access_token = this.getAccessToken();
    if (!this.isTokenExpired(access_token)) {
      console.log("no need to update access token", Utils.getTokenExpirationDate(access_token).toISOString());
      return access_token;
    }
    return this.refreshAccessToken(componentObject);
  }

  static getAccessToken() {
    let cookies = new Cookies();
    return cookies.get("access-token");
  }

  static setAccessToken(accessToken) {
    let cookies = new Cookies();
    if (!accessToken) {
      cookies.remove("access-token");
      return null;
    }
    cookies.set("access-token", accessToken);
    return accessToken;
  }

  static isLogged() {
    //todo improve method, sometimes work incorrectly, maybe send a request (no!) to understand if it is still valid?
    let ac_token = this.getAccessToken();
    if (!ac_token) {
      return false;
    }
    return !this.isTokenExpired(ac_token)
  }

  static getRefreshToken() {
    let cookies = new Cookies();
    return cookies.get("refresh-token")
  }

  static setRefreshToken(refreshToken) {
    let cookies = new Cookies();
    if (!refreshToken) {
      cookies.remove("refresh-token");
      return null;
    }
    cookies.set("refresh-token", refreshToken);
    return refreshToken;
  }

  static getTokenExpirationDate(token) {
    if (typeof token === "string") {
      token = jwtDecode(token);
    }
    return token ? new Date((token.exp) * 1000) : null;
  }

  static deleteToken(token) {
    if (!token) {
      alert("fd");
      // this.showMessage()
    }
    if (token.type === 'access') {
      this.setAccessToken(null);
    } else if (token.type === 'refresh') {
      this.setRefreshToken(null);
    }
  }

  static isTokenExpired(token) {
    let currentTime = new Date().getTime();
    let tokenTime = this.getTokenExpirationDate(token).getTime();
    return tokenTime < currentTime; //if token time before current then token is expired
  }

  //todo: introduce a decorator for post or get methods and call this method inside if needed
  static refreshAccessToken(componentObject) {
    this.cookies = new Cookies();
    let site = Property.BASE_API_URL;
    let updateTokenUrl = site + "/api/refresh";
    let refreshToken = this.getRefreshToken();
    if (!refreshToken) {
      if (componentObject) {
        this.showMessage(componentObject, 'No refresh token exist.');
      }
      return null;
    }
    let updateConfig = {
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Methods': 'POST',
        'Access-Control-Allow-Origin': site,
        'Authorization': "JWT " + refreshToken
      }
    };

    return axios.post(updateTokenUrl, null, updateConfig)
      .then((response) => {
        console.log("update token response: ", response);
        Utils.setAccessToken(response.data.access_token);
        return response.data.access_token;
      }).catch((error) => {
        console.log(error);
        if (error.response == null) {
          console.log('404 ', error);
        } else if (error.response.status === 401) {
          console.log('401 ', error);
        }
        this.showMessage(componentObject, error);
      });
  }
}

export default Utils