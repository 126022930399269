// ColorPalette.tsx
import React, {useState} from 'react';

interface ColorPaletteProps {
    onColorSelected: (color: string) => void;
    direction?: 'horizontal' | 'vertical'; // Added direction prop
}

interface ColorOptionProps {
    color: string;
    selectedColor: string;
    onChange: (color: string) => void;
    onColorSelected: (color: string) => void;
}

const ColorOption: React.FC<ColorOptionProps> = ({color, selectedColor, onChange, onColorSelected}) => {
    return (
        <div style={{display: 'inline-block', marginRight: '5px', marginBottom: '5px'}}>
            <input
                type="radio"
                id={color}
                name="color"
                value={color}
                checked={selectedColor === color}
                onChange={() => onChange(color)}
                style={{display: 'none'}}
            />
            <label aria-label={'Set color ' + color}
                htmlFor={color}
                style={{
                    backgroundColor: color,
                    width: '20px',
                    height: '20px',
                    display: 'inline-block',
                    cursor: 'pointer',
                    border: '1px solid indigo'
                }}
                onClick={() => onColorSelected(color)}
            />
        </div>
    );
};

const ColorPalette: React.FC<ColorPaletteProps> = ({onColorSelected, direction = 'horizontal'}) => {
    const [selectedColor, setSelectedColor] = useState<string>('red');

    const handleColorChange = (color: string) => {
        setSelectedColor(color);
    };

    const paletteStyle: React.CSSProperties = {
        display: 'flex',
        flexDirection: direction === 'horizontal' ? 'row' : 'column',
    };

    return (
        <div style={paletteStyle}>
            <ColorOption color="black" selectedColor={selectedColor} onChange={handleColorChange}
                         onColorSelected={onColorSelected}/>
            <ColorOption color="crimson" selectedColor={selectedColor} onChange={handleColorChange}
                         onColorSelected={onColorSelected}/>
            <ColorOption color="white" selectedColor={selectedColor} onChange={handleColorChange}
                         onColorSelected={onColorSelected}/>
        </div>
    );
};

export default ColorPalette;
