import React, {Component} from "react";
import {BrowserRouter, NavLink, Route, Routes} from "react-router-dom";
import Home from "./Home";
import {Helmet} from "react-helmet";
import Project from "./Project";
import NotFound from "./NotFound";
import PostList from "./PostList";
import Post from "./Post";
import Profile from "./Profile"
import Cookies from "universal-cookie";
import Utils from "./Utils";
import Login from "./Login";
import Paint from "./paint/Paint";

interface UserState {
  isLogged: boolean;
  username: string;
}

class Main extends Component<{}, UserState> {

  renderToken = () => {
  };

  logout = () => {
    Utils.setRefreshToken(null);
    Utils.setAccessToken(null);
    console.log("access-token is removed");
    this.setState({
      isLogged: false,
      // username: null
    });
    window.location.replace("/");
  };
  cookies: Cookies;

  onChangeTheme(event: any) {
    let pattern_img_name; // it is default, we won't handle it separately
    switch (event.target.value) {
      case "bones":
        pattern_img_name = "skulls.png"
        break;
      case "space":
        pattern_img_name = "space.png"
        break;
      default:
        pattern_img_name = "banana.jpg"
    }
    document.body.style.backgroundImage = 'url(/' + pattern_img_name + ')';
  }

  constructor(props: Cookies) {
    super(props);
    this.cookies = new Cookies();
    this.state = {
      username: this.cookies.get("username"),
      isLogged: Utils.getAccessToken()
    };

    this.renderToken = this.renderToken.bind(this);
    this.renderToken();
    /**
     *  setInterval(this.renderToken, 60000 * 5); //find better way
     *  todo access token must be updated only when it is needed,
     *    introduce Observable and subscribe to it?
     */
    this.onChangeTheme = this.onChangeTheme.bind(this);
  }

  render() {
    let loginLogoutButton = this.state.isLogged
        ? <NavLink to="" onClick={this.logout}>Logout</NavLink>
        : <NavLink to="/login">Login</NavLink>;

    let profileLink = this.state.isLogged
        ? <li className={"menu-item-left"}><NavLink to="/profile" style={{color: "yellow"}}
                                                    replace>{this.state.username}</NavLink></li>
        : null;

    return (
        <div>
          <Helmet>
            <link rel="icon" href={window.location.origin + "/favicon.ico"} type="image/x-icon" sizes="16x16"/>
            <link rel="icon" href={window.location.origin + "/favicon120.png"} type="image/png" sizes="120x120"/>
            <link rel="icon" href={window.location.origin + "/favicon120.svg"} type="image/svg+xml" sizes="120x120"/>
            <link rel="icon" href={window.location.origin + "/favicon256.png"} type="image/png" sizes="256x256"/>
            <link rel="icon" href={window.location.origin + "/favicon256.svg"} type="image/svg+xml" sizes="any"/>
          </Helmet>
          <BrowserRouter>
            <div id="theme-select-container" onClick={this.onChangeTheme}>
              <ul style={{display: "block", listStyleType: "disc"}} className="line, hidden">
                <li>
                  <input type={"radio"} id="banana-style" name="interface-style" value="banana" defaultChecked/>
                  <label htmlFor="banana-style">🍌 Banana</label>
                </li>
                <li>
                  <input type={"radio"} id="bones-style" name="interface-style" value="bones"/>
                  <label htmlFor="bones-style">💀 Bones</label>
                </li>
                <li>
                  <input type={"radio"} id="space-style" name="interface-style" value="space"/>
                  <label htmlFor="space-style">🪐 Space</label>
                </li>
              </ul>
            </div>
            <div>
              <h1 className={"header"}>barbariania.com</h1>
              <ul className="menu-list">
                <li><NavLink end to="/" replace>Home</NavLink></li>
                <li><NavLink to="/project" replace>Projects</NavLink></li>
                <li><NavLink to="/post-list" replace>Articles</NavLink></li>
                {this.state.isLogged ? <li><NavLink to="/post" replace>Post</NavLink></li> : null}
                <li><NavLink to={"/paint"} replace>Paint</NavLink></li>
                <li className={"menu-item-left"}>{loginLogoutButton}</li>
                {profileLink}
              </ul>
              <div className="content">
                <Routes>
                  <Route path="/" element={<Home/>}/>
                  <Route path="/project" element={<Project/>}/>
                  <Route path="/post-list" element={<PostList/>}/>
                  <Route path="/post/:id?" element={<Post/>}/>
                  <Route path="/paint" element={<Paint/>}/>
                  <Route path="/login" element={<Login/>}/>
                  <Route path="/profile" element={<Profile/>}/>
                  <Route element={<NotFound/>}/>
                </Routes>
              </div>
            </div>
          </BrowserRouter>
        </div>
    );
  }
}

export default Main;