import * as React from "react";
import ColorPalette from './ColorPalette';

import "./paint.css"

const scale = 4;
const width = 60;
const height = 60;

let canvas: HTMLCanvasElement;
let context: CanvasRenderingContext2D;
let canvasBounds: DOMRect;

function fillCanvas(color: string) {
  context.fillStyle = color;
  context.fillRect(0, 0, canvas.width, canvas.height);
}

function getCurrentTimestamp(): string {
  return new Date().toISOString();
}

function logToForm(message: string) {
  const pageLogBlock: HTMLElement = document.getElementById("painting-info-message") as HTMLElement;
  pageLogBlock.innerHTML += `<span><span style='color: red'>${getCurrentTimestamp()}</span>>:${message}</span>`;
}

// Handle color
function handleColorSelected(color: string) {
  context.strokeStyle = color;
  logToForm(`Brush color is changed to ${color}`);
}

//Example got from https://codepen.io/alperentalaslioglu/pen/yPGgvP
// Scaling canvas: https://devlog.disco.zone/2016/07/22/canvas-scaling/
function installCanvasHandlers() {
  const clearButton: HTMLButtonElement = document.getElementById('clear') as HTMLButtonElement;
  const recognizeButton: HTMLButtonElement = document.getElementById("recognize") as HTMLButtonElement;
  const saveButton: HTMLButtonElement = document.getElementById('save') as HTMLButtonElement;

  context.imageSmoothingEnabled = false;
  context.scale(scale, scale);

  canvas.width = scale * width;
  canvas.height = scale * height;
  canvas.style.width = `${scale * width}px`;
  canvas.style.height = `${scale * height}px`;

  canvasBounds = canvas.getBoundingClientRect();

  fillCanvas("white");

  // Specifications
  let mouseX = 0;
  let mouseY = 0;
  setBrushColor('black')
  context.lineWidth = 1; // initial brush width
  let isDrawing = false;

  function setMouseCoordinates(event: MouseEvent): void {
    mouseX = event.clientX - canvasBounds.left;
    mouseY = event.clientY - canvasBounds.top;
  }

  function setTouchCoordinates(event: TouchEvent): void {
    mouseX = event.touches[0].clientX - canvasBounds.left;
    mouseY = event.touches[0].clientY - canvasBounds.top;
  }

  function setBrushColor(colorName: string): void {
    context.strokeStyle = colorName;
  }

  function drawDot(): void {
    context.beginPath();
    context.arc(mouseX, mouseY, 10, 0, Math.PI * 2);
    context.fillStyle = context.strokeStyle; // Set the filling color of the dot
    context.fill();
  }

  function setCursorCoordinates(event: MouseEvent | TouchEvent) {
    if (event instanceof MouseEvent) {
      setMouseCoordinates(event);
    } else if (event instanceof TouchEvent && event.touches.length > 0) {
      setTouchCoordinates(event);
    }
  }

  function drawOnCanvas(event: MouseEvent | TouchEvent) {
    event.preventDefault();
    setCursorCoordinates(event);
    drawDot();
    isDrawing = true;

    context.beginPath();
    context.moveTo(mouseX, mouseY);
  }
  canvas.addEventListener('mousedown', drawOnCanvas);
  canvas.addEventListener('touchstart', drawOnCanvas);

  // Mouse Move Event
  function paintPath(event: MouseEvent | TouchEvent) {
    setCursorCoordinates(event);

    if (isDrawing) {
      context.lineWidth = 20;
      if (event instanceof TouchEvent && event.changedTouches.item(0)) {
        context.lineWidth = event.changedTouches.item(0)?.radiusX || 20;
      }
      context.lineTo(mouseX, mouseY);
      context.stroke();
    }
  }
  canvas.addEventListener('mousemove', paintPath);
  canvas.addEventListener('touchmove', paintPath);

  function stopDrawing(event: MouseEvent | TouchEvent) {
    setCursorCoordinates(event);
    isDrawing = false;
  }
  canvas.addEventListener('mouseup', stopDrawing);
  canvas.addEventListener('touchend', stopDrawing);

  clearButton.addEventListener('click', function () {
    context.clearRect(0, 0, canvas.width, canvas.height);
    fillCanvas("white");
  });

  document.getElementById("description-hide-show-button")?.addEventListener('click', function (){
    const descriptionBlock: HTMLElement = document.getElementById("description") as HTMLElement;
    descriptionBlock.hidden = !descriptionBlock.hidden;
  });

  // Handle Recognize Button
  recognizeButton.addEventListener('click', function () {
    const imageData = context.getImageData(0, 0, canvas.width, canvas.height);

    let body = {
      "recognize": true,
      "content": canvas.toDataURL(),
    }

    // https://rapidapi.com/guides/fetch-api-async-await
    let uri = `https://rwro5c7okmm4rehhu2pe5fzjru0cmndq.lambda-url.eu-central-1.on.aws?width=${imageData.width}&height=${imageData.height}&colorSpace=${imageData.colorSpace}`;

    async function fetchRecognition() {
      const response = await fetch(uri, {
        body: JSON.stringify(body),
        method: 'POST',
        mode: 'cors',
        headers: {
          'Accept': 'application/json',
        }
      });
      return await response.json();
    }

    fetchRecognition().then(response => {
      const respBody = response?.body;
      if (respBody) {
        console.log(respBody);
        logToForm(JSON.stringify(respBody, null, 2));
      }
    }).catch(exception => {
      // fetch API problem: in case of CORS the server must send header "Access-Control-Allow-Origin":"*"
      console.error(exception);
      logToForm(`Failed to recognize: ${exception.message}`);
    });
  });

  saveButton.addEventListener('click', function () {
    let canvasDataURL = canvas.toDataURL();
    let a = document.createElement('a');
    a.href = canvasDataURL;
    a.download = 'barbariania-drawing-' + getCurrentTimestamp().replace(/\D/g, '');
    a.click();
    logToForm("picture " + a.download + ".png is saved");
  });
}


class Paint extends React.Component {
  private listenersAttached = false;
  handleWindowResize = () => {
    canvasBounds = canvas.getBoundingClientRect();
  };

  constructor(props: any) {
    super(props);
  }

  componentDidMount() {
    canvas = document.getElementById("paint-canvas") as HTMLCanvasElement;
    context = canvas.getContext("2d", {willReadFrequently: true}) as CanvasRenderingContext2D;
    window.addEventListener('resize', this.handleWindowResize);
    if (!this.listenersAttached) {
      installCanvasHandlers();
      this.listenersAttached = true; // Set a flag to indicate that listeners are attached
    }
  }

  render() {
    return (
      <main>
        <div id="paint-block" style={{float: "left", width:"240px"}}>
          <canvas id="paint-canvas" width="560px" height="560px">Update your browser to support HTML5 Canvas
          </canvas>
          <ColorPalette direction={"horizontal"}
                        onColorSelected={(color) => handleColorSelected(color)}/>
        </div>
        <div className={"tools-block"} style={{float: "left", minWidth:"200px"}}>
          <button id="clear" type="button" aria-label="Clear painting">Clear</button>
          <button id="recognize" type="button" aria-label="" about="ML will try to recognize the value">Recognize</button>
          <button id="save" type="button">Save</button>
          <button id="description-hide-show-button" type="button">Hide/Show description</button>
          <div id="description" style={{minWidth: "200px"}}>Well, for now it allows you only to paint something and save to your computer 🙂
            If you write a number and press recognize, it will use ML model trained on mnist dataset
            and try to guess the number. It is not AI (humanity haven't invented AI in its classic understanding yet)🤖
            I refused to use tensorflow/keras or other frameworks as well as well-trained model
            and did everything by my own with the help of internet. It's not rocket science 🚀
            In future I'll make modifications to fit ideas in my mind.
            Stay tuned 🧠
          </div>
          <hr/>
          <div id="painting-info-message"/>
        </div>
      </main>
    );
  }
}

export default Paint