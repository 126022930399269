import React, {useState, useEffect, ChangeEvent} from "react";
import axios from "axios";
import Property from "./Property";
import Cookies from "universal-cookie";
import Utils from "./Utils";
import {jwtDecode} from "jwt-decode";
import {FaEye, FaEyeSlash} from "react-icons/fa";

// Define token type
interface Token {
  jti: string;
  type: string;
  exp: number;
  showToken: boolean;
}

// Define component state type
interface ProfileState {
  message: string;
  messageColor: string;
  username: string;
  tokens: Token[];
}

const Profile: React.FC = () => {
  const [state, setState] = useState<ProfileState>({
    message: "",
    messageColor: "#f00",
    username: "",
    tokens: [],
  });

  const cookies = new Cookies();

  useEffect(() => {
    fetchProfileInfo();
    fetchTokens();
  }, []);

  const fetchTokens = () => {
    const accessToken = Utils.getAccessToken();
    const refreshToken = Utils.getRefreshToken();
    if (!accessToken && !refreshToken) {
      return;
    }

    let tokens_arr: Token[] = [];
    if (Utils.isTokenExpired(accessToken)) {
      if (!refreshToken || Utils.isTokenExpired(refreshToken)) {
        Utils.setAccessToken(null);
        Utils.setRefreshToken(null);
      } else {
        Utils.refreshAccessToken();
      }
    }

    if (accessToken) {
      const access_token_decoded = jwtDecode<Token>(Utils.getAccessToken());
      tokens_arr.push({...access_token_decoded, showToken: false});
    }

    if (refreshToken) {
      const refresh_token_decoded = jwtDecode<Token>(Utils.getRefreshToken());
      tokens_arr.push({...refresh_token_decoded, showToken: false});
    }

    // todo: tokens must also be got from in-memory DB and must be listed here
    // to have ability to view your tokens from the other device
    setState((prevState) => ({...prevState, tokens: tokens_arr}));

    if (!accessToken) {
      window.location.replace("/");
    }
  };

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setState((prevState) => ({...prevState, username: event.target.value}));
  };

  const fetchHeaders = () => {
    const site = Property.BASE_API_URL;
    return {
      headers: {
        "Cache-Control": "no-cache",
        "Content-Type": "application/json",
        "Access-Control-Allow-Methods": "GET",
        "Access-Control-Allow-Origin": site,
        Authorization: "JWT " + Utils.getAccessToken(),
      },
    };
  };

  const fetchProfileInfo = () => {
    const url = Property.BASE_API_URL + "/api/users/me";
    const config = fetchHeaders();

    axios
      .get(url, config)
      .then((response) => {
        setState((prevState) => ({...prevState, username: response.data.username}));
      })
      .catch((error) => {
        if (
          (error.response && error.response.data.code === "access_token_expired") ||
          error.response.status === 401
        ) {
          const accessToken = Utils.refreshAccessToken();
          if (accessToken) {
            fetchProfileInfo();
          }
          return;
        }
        const accessToken = Utils.getAccessToken();
        if (!accessToken) {
          Utils.setAccessToken(null);
          window.location.replace("/");
        }
        console.log(error);
      });
  };

  const updateUserInfo = () => {
    const headers = fetchHeaders();
    const url = Property.BASE_API_URL + "/api/users";

    const requestBody = {username: state.username};

    axios
      .put(url, requestBody, headers)
      .then((response) => {
        setState((prevState) => ({
          ...prevState,
          message: "Update succeeded",
          messageColor: "#0f0",
        }));
      })
      .catch((error) => {
        setState((prevState) => ({
          ...prevState,
          message: `${error.response.status}: ${error.response.data.message}`,
          messageColor: "#f00",
        }));
      });

    setTimeout(() => {
      setState((prevState) => ({...prevState, message: ""}));
    }, Property.MESSAGE_TIMEOUT_MILLISECONDS);
  };

  const toggleTokenVisibility = (index: number) => {
    const updatedTokens = state.tokens.map((token, idx) =>
      idx === index ? {...token, showToken: !token.showToken} : token
    );
    setState((prevState) => ({...prevState, tokens: updatedTokens}));
  };

  const maskToken = (token: string) => {
    if (token.length <= 8) {
      return token; // If the token length is 8 or less, don't mask
    }
    const maskedLength = token.length - 8;
    return token.slice(0, 4) + '*'.repeat(maskedLength) + token.slice(-4);
  };

  const {message, messageColor, username, tokens} = state;

  return (
    <div className="content-holder">
      <h2>Profile settings</h2>
      <span id="message" style={{color: messageColor}}>
        {message}
      </span>
      <p>Name</p>
      <input
        type="text"
        placeholder="name"
        id="name"
        value={username}
        onChange={handleChange}
      />
      <button onClick={updateUserInfo}>Save</button>
      <p>Active tokens</p>
      <table>
        <thead>
        <tr>
          <th style={{width: "320px"}}>JTI (identifier)</th>
          <th>Type</th>
          <th>Valid until</th>
          <th>Actions</th>
        </tr>
        </thead>
        <tbody>
        {tokens.map((token, index) => (
          <tr key={token.jti}>
            <td>
              {token.showToken ? token.jti : maskToken(token.jti)}
              <span onClick={() => toggleTokenVisibility(index)}
                    style={{cursor: "pointer", marginLeft: "10px", float: "right"}}>
                  {token.showToken ? <FaEyeSlash/> : <FaEye/>}
                </span>
            </td>
            <td>{token.type}</td>
            <td>{Utils.getTokenExpirationDate(token)?.toISOString()}</td>
            <td>
              <button
                onClick={() => {
                  Utils.deleteToken(token);
                  fetchTokens();
                }}
              >
                Delete
              </button>
            </td>
          </tr>
        ))}
        </tbody>
      </table>
      <p>Latest events</p>
      <span>
        (yet it is empty, not filled and not supported, later here will be list of comments, profile updates etc. provided by <strong>Event Sourcing)</strong>
      </span>
      <table>
        <thead>
        <tr>
          <th>Sequence</th>
          <th>Operation</th>
          <th>Request Body</th>
          <th>Result</th>
        </tr>
        </thead>
        <tbody>
        <tr>
          <td>001</td>
          <td>UPDATE</td>
          <td>{"{some json}"}</td>
          <td>200, SUCCESS / PROCESSING / ...</td>
        </tr>
        </tbody>
      </table>
    </div>
  );
};

export default Profile;
