import React from "react";

class Home extends React.Component {
  render() {
    return (
      <div>
        <h2>HI there!</h2>
        <p>My name is Aliaksei. That is my personal blog. It will reflect with the topics I'm interested in
          or have my personal view. You can agree or disagree with the info here and welcome to leave comments.
        </p>
        <p>
          <strong>Mission</strong> of the service is to be a knowledge base of my ideas and the ideas
          I find worth spreading.
        </p>
        {/* todo: add links to search criteria in languages*/}
        <p>Here may be advertisement I'd recommend you to have a look, no paid advertisement.</p>
        <p>There will be either links on projects I'm working on. The topics can be in English, Русский, Deutsch
          or Español - it depends on my language competency and personal suitability.
        </p>
        <p>

          You can check out <a href="https://t.me/BarbarianiaBot">
           🤖Telegram bot
        </a> and be notified by the topics
          in which you're interested.
        </p>
        <p>
          In case of any questions I'm reachable by
          &nbsp;✉️<a href="mailto:a.ryzhkou@gmail.com">a.ryzhkou@gmail.com</a>
          &nbsp;or <img src={window.location.origin + "/telegram_logo.svg"}
                        alt={"telegram_logo"}
                        style={{maxWidth: "16px"}}/><a href="https://t.me/BarbarianiaBot">telegram</a>
        </p>

        <p>Welcome and have a good mood!</p>

        <hr/>
        <p>PS/ if you found any issues, please provide your case in the following trackers
          (in any if you can't understand the reason)</p>
        <ul>
          <li>[BACKEND] <a href="https://bitbucket.org/barbariania/blog-server-python/issues">
            https://bitbucket.org/barbariania/blog-server-python/issues</a></li>
          <li>[FRONTEND] <a href="https://bitbucket.org/barbariania/blog-ui-reactjs/issues">
            https://bitbucket.org/barbariania/blog-ui-reactjs/issues</a></li>
        </ul>
      </div>
    );
  }
}

export default Home
