import React from "react";

class Project extends React.Component {
  render() {
    return (
      <div>
        <h2>
          Active Project Links
        </h2>
        <hr/>
        <div>
          <h3><a href="https://www.amovent.com">amovent.com</a></h3>
          <h4>Social Sport Network - Cooperate with people due to your interests, create and attend events you're interested in!</h4>
          <p>Social network aimed to create teams, organize events, participate in compentitions and of course just to be in sport theme.
              You create badges due to your interests - volleyball, football, wrestling, curling, cycling (etc.) - and you'll be notified
              about that kinds of events via email. That way you can see the participants, organizers
              and any info about event,chat with other participants, decide to participate or ignore the particular event - it's up to you!</p>
        </div>
        <hr/>
        <div>
          <h3>[inactive] <a href="https://bitbucket.org/barbariania/lego-price-scrapper">Lego Price Scrapper (*.by domain)</a></h3>
          <h4>Data scrapper for LEGO Sets in Belarus by predefined sites</h4>
          <p>Finds the desired set by its theme (like "Technic", "City", "Mindstorms") and set name (like 42114, 60249, 51515)
            and shows the results with name, link and price by sites. You can choose a cheaper or more preferable for you.</p>
        </div>
        <hr/>
        <div>
          <h3><a href={"/paint"}>ML paint</a> (prototype, learning, have no desired direction now)</h3>
          {/*<h4></h4>*/}
          <p>Paint one of the figures that ML can guess and let's see if it succeed in it!</p>
        </div>
        <hr/>
      </div>
    );
  }
}

export default Project
