import React from 'react';
import axios from "axios";
import Property from "./Property";

class PostList extends React.Component {

  componentDidMount() {
    this.fetchPosts();
    this.fetchTags();
  }

  constructor(props) {
    super(props);
    this.state = {
      items: [],
      tags: [],
      error: null,
      tagsSelected: [],
      pattern: null
    };
    this.onTagSelect = this.onTagSelect.bind(this);
    this.onPatternChange = this.onPatternChange.bind(this);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevState.tagsSelected.length !== this.state.tagsSelected.length
      || prevState.pattern !== this.state.pattern
    ) {
      this.fetchPosts();
    }
    console.log("Search posts for tags=%s and pattern=%s", JSON.stringify(this.state.tagsSelected), this.state.pattern);
  }

  onTagSelect(tagName) {
    const newTagsSelected = this.state.tagsSelected.indexOf(tagName) === -1
      ? [...this.state.tagsSelected, tagName]
      : this.state.tagsSelected.filter(tag => tag !== tagName);
    this.setState({tagsSelected: newTagsSelected});
  }

  onPatternChange() {
    let patternVal = document.getElementById('patternInText').value;
    this.setState({pattern: patternVal ? patternVal : null})
  }

  showHideTags() {
    let tagsContainer = document.getElementById("tags-container");
    tagsContainer.style.display = tagsContainer.style.display === "none" ? "flow-root" : "none";
  }

  getIsModified(id) {
    // fixme need to show 'modified' only in case it is really modified - clear storage if not modified after close page
    let itemInStorage = localStorage.getItem(id)
    return !!itemInStorage;
  }

  fetchTags() {
    axios.get(Property.BASE_API_URL + "/api/tags")
      .then((response) => {
        console.log("tags : ", response.data);
        this.setState({tags: response.data});
      }).catch((err) => {
        let message = this.extractError(err);
        let error = {type: 'FETCH_TAGS_ERROR', message: message, payload: err};
        this.setState({error: error});
        console.log(this.state.error);
      });
  }

  extractError(err) {
    let message = err.message === 'Network Error' ? '404: ' + err.message : err.message;
    if (err.response && err.response.data && err.response.data.message) {
      message = err.response.data.message;
    }
    return message;
  }

  fetchPosts() {
    let url = Property.BASE_API_URL + "/api/posts?";
    if (this.state.tagsSelected.length > 0) {
      url += "tags=" + this.state.tagsSelected.join(",");
    }
    if (this.state.pattern) {
      url += "&pattern=" + this.state.pattern;
    }
    console.log(url);
    axios.get(url)
      .then((response) => {
        console.log("posts : ", response.data);
        this.setState({items: response.data});
      }).catch((err) => {
        let message = this.extractError(err);
        let error = {type: 'FETCH_POSTS_ERROR', message: message, payload: err};
        this.setState({error: error});
        console.log(this.state.error);
      });
  }

  render() {
    let tagItems = this.state.tags;
    let postItems = this.state.items;

    //todo: show on page '[updated but not saved]' if has differences in localStorage with got from db
    //todo: pagination list
    //todo: free localStorage if no differences with saved in database,
    // that way put nothing in localstorage on fetchData and remove localstorage[id] if has smth
    return (
      <div>
        <div style={{
          border: '1px solid black',
          minHeight: '50px',
          margin: "10px"
        }}>
          <button style={{color: 'blueviolet', margin: 3}} onClick={this.showHideTags}>show / hide tags</button>
          <div id="tags-container" style={{display: "none"}}>
            {
              tagItems ? Object.keys(tagItems).map((tagName, index) =>
                  <TagItemElement key={index} name={tagName} quantity={tagItems[tagName]}
                                  onTagSelect={this.onTagSelect}/>)
                : "No tags found"
            }
          </div>
          <input id='patternInText' onChange={this.onPatternChange} placeholder={"Search by pattern"}/>
        </div>
        <div>
          <p>Found {postItems.length} articles</p>
          {postItems.length === 0
            ? <span id="error-message">{
              this.state.error
                ? this.state.error.message
                : "No articles found"
            }</span>
            : postItems.map(item => <PostListElement key={item._id} post={item}
                                                     isModified={this.getIsModified(item._id)}/>)
          }
        </div>
      </div>
    );
  }
}

class TagItemElement extends React.Component {

  constructor(props) {
    super(props);
    this.state = {active: false};
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick() {
    this.setState({active: !this.state.active});
    this.props.onTagSelect(this.props.name);
  }

  render() {
    return (
      <div onClick={this.handleClick}
           className='tag-item-element'
           style={{
             backgroundColor: this.state.active ? Property.COLOR_GREEN : Property.COLOR_PINK,
           }}>
        {this.props.name} ({this.props.quantity})
      </div>
    );
  }
}

class PostListElement extends React.Component {
  render() {
    return (
      <div className="div-post" key={this.props.post._id}>
        <span className={"post-modified-span"} style={{display: this.props.isModified ? "" : "none"}}>modified</span>
        <h3><a href={"/post/" + this.props.post._id} style={{cursor: "pointer"}}>{this.props.post.name}</a></h3>
        <p className="post-timestamp">{this.props.post.updated ? this.props.post.updated : this.props.post.created}</p>
        <p>tags: {JSON.stringify(this.props.post.tags, null, 4)}</p>
        <p className={"post-content-preview"}>{this.props.post.content.substring(0, 300)}...</p>
      </div>
    );
  }
}

export default PostList