import React from 'react'

const NotFound = () => {
  return (
    <div id="wrapper">
      <h2>404 - No such page found</h2>
      <p>Seems like you have found a black hole. May be something was here but now is not.</p>
      <img src={window.location.origin + "/nasa_blackhole_visualization.gif"}
           alt={"Nasa black hole visualization"}
           style={{maxWidth: "100%"}}/>
      <div id="info">
      </div>
    </div>
  )
}

export default NotFound