import React from "react";
import axios from "axios";
import Cookies from "universal-cookie";
import Property from "./Property";
import Utils from "./Utils";

class Login extends React.Component {

  constructor(props) {
    super(props);
    this.cookies = new Cookies();
    this.state = {
      username: "",
      password: "",
      message: ""
    };
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    document.addEventListener("keydown", this.handleKeyDown, false);
  }

  handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      console.log('do validate')
      this.loginRequest();
    }
  }

  loginRequest = event => {
    axios.post(Property.BASE_API_URL + '/api/users/auth', {
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Methods': 'POST',
        'Access-Control-Request-Method': 'POST'
      },
      'username': this.state.username,
      'password': this.state.password
    })
      .then(res => {
        let accessToken = res.data.access_token;
        let refreshToken = res.data.refresh_token;
        this.cookies.set("username", this.state.username);
        Utils.setAccessToken(accessToken);
        Utils.setRefreshToken(refreshToken);
        console.log("username: " + this.cookies.get("username"));
        console.log("access-token: " + Utils.getAccessToken());
        console.log("refresh-token:" + Utils.getRefreshToken());
        this.setState({message: "Login success"});
        window.location.replace("/profile");
      }).catch((error, status, info) => {
      let message = error.message === 'Network Error'
        ? 'Network Error: No response from backend'
        : error.response.status + " : " + error.response.data.message;
      console.log(error);
      this.setState({message: message});
    });

    setTimeout(function () {
      this.setState({message: ""});
    }.bind(this), Property.MESSAGE_TIMEOUT_MILLISECONDS);
  };

  handleChange(event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.id;

    this.setState({
      [name]: value
    });
  }

  render() {
    return (
      <div>
        <span id="message" value={this.state.message}>{this.state.message}</span>
        <h2>Login page</h2>
        <input type="text" id="username" value={this.state.username} onChange={this.handleChange}/>
        <input type="password" id="password" value={this.state.password} onChange={this.handleChange}/>
        <button onClick={this.loginRequest}>Login</button>
      </div>
    );
  }
}

export default Login